<template>
  <div class="page">
    <LoadingOverlay v-if="isUploading" />
    <div>
      <Registration
        v-if="!candidateProfile"
        :customer="getCustomerID"
        :organisationID="getOrgID"
        :locationID="getLocID"
        :isCandidateSupplier="isCandidateSupplier"
        @submit="create"
        @upload="upload"
        :profilePhoto="profilePhoto"
        @change="upload"
      />
      <div
        v-else-if="candidateProfile.user_id"
        class="jumbotron bg-white text-center m-auto"
      >
        <div class="login">
          <div class="message">
            <h3>Registration Successful !!</h3>
          </div>
          <div style="padding-bottom: 100px !important; padding-top: 100px !important">
            <button
              @click="resetCandidate()"
              class="btn rounded-0 px-3 btn-primary btn-lg mx-4"
            >
              Register Next Candidate
            </button>
            <button
              @click="$router.push('/candidate-list')"
              class="btn rounded-0 px-5 btn-primary btn-lg"
            >
              Home
            </button>
          </div>
        </div>
      </div>
      <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
        <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
      </CToaster>
    </div>
  </div>
</template>

<script>
import { LOGIN_URL, Variant } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import Registration from "@/containers/CandidateProfile/Registration";
import isCandidateSupplier from "@/mixins/isCandidateSupplier";

export default {
  mixins: [isCandidateSupplier],
  name: "Register",
  components: {
    Registration,
  },
  data() {
    return {
      Variant,
      profilePhoto: {},
    };
  },
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "candidateProfile",
      "getUserDetails",
      "uploadResponse",
      "randomUuid",
      "getUser"
    ]),
    isUploading() {
      return this.uploadResponse !== 0 ? true : false;
    },
    getCustomerID() {
      if (this.isCandidateSupplier && this.getUser) {
        const customer_id = this.getUser?.customer_id ? this.getUser?.customer_id : null;
        return customer_id
      }
      return null;
    },
    getOrgID() {
      if (this.isCandidateSupplier && this.getUser) {
        const org_id = this.getUser?.org_detail?.org_id ? this.getUser?.org_detail?.org_id : null;
        return org_id
      }
      return null;
    },
    getLocID() {
      if (this.isCandidateSupplier && this.getUser) {
        const location_id = this.getUser?.org_detail?.location_id ? this.getUser?.org_detail?.location_id : null;
        return location_id
      }
      return null;
    },
  },
  methods: {
    ...mapActions([
      "createCandidate",
      "uploadPhoto",
      "multipleFileUpload",
      "deleteTempDocs",
      "resetCandidate",
      "getTotalCandidates",
    ]),
    create(data) {
      let { payload, docFiles } = data;
      let appendAction = [this.createCandidate(payload)];
      appendAction = [...appendAction, this.deleteTempDocs(this.randomUuid)];
      Promise.all(appendAction).then((res) => {
        const { customer_uid, candidate_uid, user_id } = this.candidateProfile;
        docFiles.forEach((element) => {
          let { file, docType } = element;
          let { code: document_type } = docType;
          let payload = {
            file,
            document_type,
            customer_uid,
            candidate_uid,
            user_id,
          };
          this.multipleFileUpload(payload);
        });
        this.updatePhoto();
        this.getTotalCandidates();
      });
    },
    upload(payload) {
      this.profilePhoto = payload;
      this.$store.commit("SET_UPLOADED");
    },
    updatePhoto() {
      let payload = this.profilePhoto;
      const document_type = payload.document_type || 1;
      const { customer_uid, candidate_uid, user_id } = this.candidateProfile;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
          });
        }
      }
    }
  },
  mounted() {
    this.resetCandidate();
  }
};
</script>

<style lang="scss" scoped>
.message {
  @media all and (min-width: 760px) {
    width: 40%;
    margin: auto;
  }
}
.register {
  background-color: #f9f8fd;
  min-height: 100vh;
}
</style>
